.login_container__dKYZP {
  height: 100%;
  width: 100%;
  display: grid;
  /* 8rem for the header, 4rem for the footer and the middle section takes up
  the rest. */
  grid-template-rows: 8rem 1fr 4rem;
}

.login_header__5TWRo {
  padding: 3rem;
  display: flex;
  align-items: center;
}

.login_header__5TWRo ul {
  display: flex;
  list-style-type: none;
}

.login_header__5TWRo li {
  margin-left: 2rem;
}

.login_logo__GW03_ {
  position: relative;
  flex-grow: 1;
}

.login_section__Vjn12 {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  overflow: hidden;
  overflow-y: auto;
}

.login_sectionLeft__Q1rCY {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  justify-items: start;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 3rem;
}

.login_sectionLeft__Q1rCY h2 {
  font-size: 2.375rem;
  color: var(--color-primary-1);
  margin: 1rem 0;
  max-width: 30rem;
  font-weight: normal;
}
.login_sectionLeft__Q1rCY h3 {
  font-size: 1.5rem;
}
.login_sectionLeft__Q1rCY p {
  color: var(--color-text-5);
}

.login_sectionLeftHeader__uQPjp {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}
.login_sectionLeftHeader__uQPjp h1 {
  font-size: var(--sizing-font-xlarge);
  position: relative;
  font-weight: normal;
}
.login_headerText__t18iP {
  font-weight: bold;
}

.login_betaBanner__JBowi {
  color: var(--color-text-2);
  font-size: var(--sizing-font-small);
  font-weight: normal;
  width: 3rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.login_betaBanner__JBowi::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: var(--color-neutral-6);
  transform: skew(-25deg);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login_contactUs__ICUHH span {
  color: var(--color-text-4);
}

.login_sectionRight__veTww > div {
  border-radius: 0.25rem;
}

.login_footer__Xh0OZ {
  display: flex;
  align-items: center;
  position: relative;
}
.login_footer__Xh0OZ::before {
  content: '';
  display: block;
  width: 100%;
  height: 2rem;
  background: linear-gradient(
    to top,
    var(--color-neutral-3),
    /* Setting the second color to the same as the first color, but with 0%
    opacity. This is because Safari handles the gradient differently than the
    other browsers. Reference:
    https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
    */
      rgba(var(--color-neutral-3-rgb), 0)
  );
  position: absolute;
  bottom: 100%;
  left: 0;
}

.login_footer__Xh0OZ ul {
  flex-grow: 1;
  display: flex;
  list-style-type: none;
}
.login_footer__Xh0OZ li {
  margin-left: 4rem;
}
.login_footer__Xh0OZ p {
  color: var(--color-text-4);
  margin-right: 3rem;
}

.login_unsupportedBrowserContainer__VH499 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--color-black-rgb), 0.5);
  z-index: var(--zIndex-top);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_unsupportedBrowserBox__5Ed68 {
  background-color: var(--color-neutral-3);
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 1rem;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
}
.login_unsupportedBrowserText__S7Uqm {
  color: var(--color-text-2);
  text-align: center;
}
.login_unsupportedBrowserButton__3Hxgb {
  align-self: flex-end;
  margin-top: 1rem;
  border: none;
  border-radius: 0.25rem;
  background: var(--color-primary-1);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .login_header__5TWRo ul {
    display: none;
  }
  .login_section__Vjn12 {
    grid-template-columns: 100vw;
  }
  .login_sectionLeft__Q1rCY h2 {
    font-size: var(--sizing-font-xlarge);
  }
  .login_sectionRight__veTww {
    display: none;
  }
  .login_footer__Xh0OZ {
    justify-content: center;
  }
  .login_footer__Xh0OZ ul {
    display: none;
  }
  .login_footer__Xh0OZ p {
    margin: 0;
  }
}

@font-face {
ascent-override: 90%;
font-family: '__dinot_f1bd0a';
src: url(/_next/static/media/593dfae8c3e60a91-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__dinot_Fallback_f1bd0a';src: local("Arial");ascent-override: 75.53%;descent-override: 23.33%;line-gap-override: 28.47%;size-adjust: 101.15%
}.__className_f1bd0a {font-family: '__dinot_f1bd0a', '__dinot_Fallback_f1bd0a', DM Sans, Roboto, Helvetica, sans-serif;font-weight: 400;font-style: normal
}

