.container {
  height: 100%;
  width: 100%;
  display: grid;
  /* 8rem for the header, 4rem for the footer and the middle section takes up
  the rest. */
  grid-template-rows: 8rem 1fr 4rem;
}

.header {
  padding: 3rem;
  display: flex;
  align-items: center;
}

.header ul {
  display: flex;
  list-style-type: none;
}

.header li {
  margin-left: 2rem;
}

.logo {
  position: relative;
  flex-grow: 1;
}

.section {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  overflow: hidden;
  overflow-y: auto;
}

.sectionLeft {
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: max-content;
  justify-items: start;
  gap: 1rem;
  padding: 3rem;
}

.sectionLeft h2 {
  font-size: 2.375rem;
  color: var(--color-primary-1);
  margin: 1rem 0;
  max-width: 30rem;
  font-weight: normal;
}
.sectionLeft h3 {
  font-size: 1.5rem;
}
.sectionLeft p {
  color: var(--color-text-5);
}

.sectionLeftHeader {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  gap: 2rem;
}
.sectionLeftHeader h1 {
  font-size: var(--sizing-font-xlarge);
  position: relative;
  font-weight: normal;
}
.headerText {
  font-weight: bold;
}

.betaBanner {
  color: var(--color-text-2);
  font-size: var(--sizing-font-small);
  font-weight: normal;
  width: 3rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.betaBanner::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  background-color: var(--color-neutral-6);
  transform: skew(-25deg);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.contactUs span {
  color: var(--color-text-4);
}

.sectionRight > div {
  border-radius: 0.25rem;
}

.footer {
  display: flex;
  align-items: center;
  position: relative;
}
.footer::before {
  content: '';
  display: block;
  width: 100%;
  height: 2rem;
  background: linear-gradient(
    to top,
    var(--color-neutral-3),
    /* Setting the second color to the same as the first color, but with 0%
    opacity. This is because Safari handles the gradient differently than the
    other browsers. Reference:
    https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
    */
      rgba(var(--color-neutral-3-rgb), 0)
  );
  position: absolute;
  bottom: 100%;
  left: 0;
}

.footer ul {
  flex-grow: 1;
  display: flex;
  list-style-type: none;
}
.footer li {
  margin-left: 4rem;
}
.footer p {
  color: var(--color-text-4);
  margin-right: 3rem;
}

.unsupportedBrowserContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(var(--color-black-rgb), 0.5);
  z-index: var(--zIndex-top);
  display: flex;
  justify-content: center;
  align-items: center;
}
.unsupportedBrowserBox {
  background-color: var(--color-neutral-3);
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 1rem;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
}
.unsupportedBrowserText {
  color: var(--color-text-2);
  text-align: center;
}
.unsupportedBrowserButton {
  align-self: flex-end;
  margin-top: 1rem;
  border: none;
  border-radius: 0.25rem;
  background: var(--color-primary-1);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 0.75rem;
  text-transform: uppercase;
}

@media only screen and (max-width: 600px) {
  .header ul {
    display: none;
  }
  .section {
    grid-template-columns: 100vw;
  }
  .sectionLeft h2 {
    font-size: var(--sizing-font-xlarge);
  }
  .sectionRight {
    display: none;
  }
  .footer {
    justify-content: center;
  }
  .footer ul {
    display: none;
  }
  .footer p {
    margin: 0;
  }
}
